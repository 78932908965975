//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { preventHtmlTags } from '@/utils'
import {
  sectionsStore,
  teamsStore,
  uiStore,
} from '@/store'

export default {
  name: 'ContactsSectionMenu',

  props: {
    uid: {
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    showArchived: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    editOption () {
      return {
        label: this.$t('contacts.editDepartment'),
        action: this.editSection,
      }
    },
    deleteOption () {
      return {
        label: this.$t('contacts.deleteDepartment'),
        action: this.deleteSection,
      }
    },
    showArchivedOption () {
      return {
        label: this.$t('contacts.showDeleted'),
        value: this.showArchived,
        action: bool => this.$emit('show-archived', bool),
      }
    },
    canManageSections () {
      // check for uid -> edge case for contacts without section
      return this.uid && teamsStore.getters.currentTeam.me.canManageSections
    },
  },
  methods: {
    editSection () {
      uiStore.actions.showModal({ instance: 'SectionModal', payload: this.uid })
    },
    async deleteSection () {
      const message = `${this.$t('contacts.deleteText')} <b>${preventHtmlTags(this.name)}</b>?`

      const callback = async () => {
        try {
          const payload = {
            uid: this.uid,
            type: 'contact',
          }
          await sectionsStore.actions.delete(payload)
        } catch (e) {
          console.warn(`[RightBar.DELETE_SECTION err]: ${e}`)
        }
      }

      uiStore.actions.showModal({
        instance: 'universal-yes-no',
        payload: {
          title: this.$t('contacts.deleteDepartment'),
          text: message,
          yesText: this.$t('contacts.yesText'),
          yes: callback,
        },
      })
    },
  },
}
